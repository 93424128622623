import { FC, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AnimatePresence } from 'framer-motion';

interface ISideBarProps {
  closeSideBar: () => void;
  isSideBarOpen: boolean;
}

import {
  SideBarOverlay,
  SideBarWrapper,
  SideBarLinks,
  SideBarLink,
} from './mobile-sidebar.styles';
import { FarmNameSpecieContainer } from '../header/header.styles';

import SvgIcon from '../svg-icon/svg-icon.component';
import Button from '../button/button.component';

import routes from '~/helpers/routes';

import useLogOut from '~/react-query/mutations/useLogOut';

import useStore from '~/store';

const MobileSideBar: FC<ISideBarProps> = ({ isSideBarOpen, closeSideBar }) => {
  const router = useRouter();

  const { pathname } = router;

  const currentUser = useStore((state) => state.currentUser);

  const { mutate: logout } = useLogOut();

  useEffect(() => {
    router.events.on('routeChangeComplete', closeSideBar);
    router.events.on('routeChangeError', closeSideBar);

    return () => {
      router.events.off('routeChangeComplete', closeSideBar);
      router.events.off('routeChangeError', closeSideBar);
    };
  }, [router, closeSideBar]);

  return (
    <>
      {isSideBarOpen && <SideBarOverlay onClick={() => closeSideBar()} />}

      <AnimatePresence>
        {isSideBarOpen && (
          <SideBarWrapper
            key="sidebar"
            initial={{ opacity: 0, transform: 'translateX(30rem)' }}
            animate={{ opacity: 1, transform: 'translateX(0rem)' }}
            exit={{ opacity: 0, transform: 'translateX(30rem)' }}
            transition={{ duration: 0.4, ease: [0.6, 0.05, -0.01, 0.9] }}
          >
            <SvgIcon
              iconName="close"
              big
              black
              onClick={() => closeSideBar()}
            />

            <FarmNameSpecieContainer sidebar>
              {currentUser?.farm_info?.farm_name}'s{' '}
              {currentUser?.herds_info?.[0]?.name}
            </FarmNameSpecieContainer>

            <SideBarLinks>
              <SideBarLink
                isActive={
                  pathname.startsWith(routes.animals()) ||
                  pathname.startsWith(routes.herds())
                }
              >
                <Link href={routes.animals()}>
                  <a>Animals</a>
                </Link>
              </SideBarLink>

              <SideBarLink isActive={pathname.startsWith(routes.alerts())}>
                <Link href={routes.herdAlerts()}>
                  <a>Events</a>
                </Link>
              </SideBarLink>

              <SideBarLink isActive={pathname.startsWith(routes.care())}>
                <Link href={routes.vaccinations()}>
                  <a>Care</a>
                </Link>
              </SideBarLink>

              <SideBarLink
                isActive={pathname.startsWith(routes.inventoryItems())}
              >
                <Link href={routes.inventoryItems()}>
                  <a>Inventory</a>
                </Link>
              </SideBarLink>

              <SideBarLink isActive={pathname.startsWith(routes.sales())}>
                <Link href={routes.animalSales()}>
                  <a>Sales</a>
                </Link>
              </SideBarLink>

              <SideBarLink isActive={pathname.startsWith(routes.pastures())}>
                <Link href={routes.pastures()}>
                  <a>Pasture</a>
                </Link>
              </SideBarLink>

              <SideBarLink isActive={pathname.startsWith(routes.breedings())}>
                <Link href={routes.breedings()}>
                  <a>Breedings</a>
                </Link>
              </SideBarLink>

              <SideBarLink
                isActive={pathname.startsWith(routes.subscriptions())}
              >
                <Link href={routes.subscriptions()}>
                  <a>Subscription</a>
                </Link>
              </SideBarLink>

              <SideBarLink isActive={pathname === routes.profile()}>
                <Link href={routes.profile()}>
                  <a>Profile</a>
                </Link>
              </SideBarLink>

              <Button
                block
                onClick={() => {
                  closeSideBar();

                  logout();
                }}
              >
                Log out
              </Button>
            </SideBarLinks>
          </SideBarWrapper>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileSideBar;
