import { FC, useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import {
  BetaTag,
  FarmNameSpecieContainer,
  HeaderEmailContainer,
  HeaderEmailInitial,
  HeaderEmailSvgContainer,
  HeaderLogo,
  Headr,
} from './header.styles';

import SvgIcon from '../svg-icon/svg-icon.component';
import MobileSideBar from '../mobile-sidebar/mobile-sidebar.component';
import MoreOptions from '../more-options/more-options.component';
import ShowView from '../show-view/show-view.component';

import useStore from '~/store';

import useMediaQuery from '~/hooks/useMediaQuery';

import routes from '~/helpers/routes';

import useLogOut from '~/react-query/mutations/useLogOut';

const Header: FC<{ publicView?: boolean }> = ({ publicView }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const isViewportGreaterThan800px = useMediaQuery('(min-width: 50em)');

  const currentUser = useStore((state) => state.currentUser);

  const { mutate: logOut } = useLogOut();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Headr>
      <HeaderLogo>
        <Image
          src="/livestocklog.png"
          alt="Livestock log logo"
          objectFit="contain"
          layout="fill"
        />

        <BetaTag>Beta</BetaTag>
      </HeaderLogo>

      <ShowView when={!publicView}>
        {isMounted && isViewportGreaterThan800px ? (
          <HeaderEmailContainer>
            <FarmNameSpecieContainer>
              {currentUser?.farm_info?.farm_name}'s{' '}
              {currentUser?.herds_info?.[0]?.name}
            </FarmNameSpecieContainer>

            <MoreOptions
              trigger={
                <HeaderEmailSvgContainer>
                  <HeaderEmailInitial>
                    {currentUser?.first_name?.[0]}
                    {currentUser?.last_name?.[0]}
                  </HeaderEmailInitial>

                  <SvgIcon iconName="chevron-down" black small />
                </HeaderEmailSvgContainer>
              }
            >
              <Link href={routes.profile()} passHref>
                <MoreOptions.ListItem withSvg>
                  <SvgIcon iconName="person" black small />
                  Profile
                </MoreOptions.ListItem>
              </Link>

              <MoreOptions.ListItem withSvg onClick={() => logOut()}>
                <SvgIcon iconName="power-off" black small />
                Log out
              </MoreOptions.ListItem>
            </MoreOptions>
          </HeaderEmailContainer>
        ) : (
          <>
            <SvgIcon
              iconName="menu"
              black
              big
              onClick={() => setShowSidebar(true)}
            />

            <MobileSideBar
              isSideBarOpen={showSidebar}
              closeSideBar={() => setShowSidebar(false)}
            />
          </>
        )}
      </ShowView>
    </Headr>
  );
};

export default Header;
