import styled from 'styled-components';

import { colors } from '~/lib/colors';

export const MainContainer = styled.div`
  padding: 2.4rem;
  min-height: 100vh;
  display: flex;

  @media only screen and (max-width: 18.75em) {
    padding: 1.5rem;
  }
`;

export const Main = styled.main`
  width: 100%;
  background-color: ${colors.white};
  min-height: 100%;
  padding: 2.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;

  @media only screen and (min-width: 50em) {
    //25rem is the width of the desktop sidebar, 3rem is the margin-left of the desktop sidebar
    width: calc(100% - 25rem - 3rem);
  }
`;

export const PageTitleActionsContainer = styled.div<{
  noMarginTop?: boolean;
  mt6?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1.6rem;
  margin: 3rem 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${colors.gray1};

  & > *:first-child {
    font-size: 2.2rem;
    font-weight: 700;
    text-transform: capitalize;
    color: ${colors.primary};
  }

  ${({ noMarginTop }) => noMarginTop && 'margin-top: 0;'}

  ${({ mt6 }) => mt6 && 'margin-top: 6rem;'}
`;

export const SummaryItem = styled.article<{ buttonsContainer?: boolean }>`
  position: relative;
  padding: 8px 2rem;
  text-transform: capitalize;
  background-color: ${colors.white1};
  color: ${colors.black1};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > a {
    font-size: 1.4rem;
    padding: 8px 1.5rem;
    border-radius: 5px;
  }

  &:nth-child(odd) {
    &::before {
      background-color: ${colors.gray1};
    }
  }

  &:nth-child(even) {
    &::before {
      background-color: ${colors.gray2};
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 75%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    pointer-events: none;
  }

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  @media only screen and (max-width: 37.5em) {
    flex-direction: column;
    align-items: flex-start;

    & > a {
      padding: 6px 1.2rem;
      width: min(15rem, 100%);
      margin: 1.2rem auto;
      margin-bottom: 1rem;
    }
  }

  ${({ buttonsContainer }) =>
    buttonsContainer &&
    `
    & > div:last-child {
      display: flex;

      & > button, & > a {
        font-size: 1.4rem;
        padding: 8px 1.5rem;
        border-radius: 5px;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }

      @media only screen and (max-width: 37.5em) {
        margin: 1.2rem auto;
        margin-bottom: 1rem;
      
        & > button {
          padding: 6px 1.2rem;
          width: min(15rem, 100%);
        }
      }
    }
  `}
`;

export const SummaryItemTextContainer = styled.div`
  margin-right: 1rem;

  & > p > span:first-of-type {
    font-weight: 400;
  }
`;

export const InfoTagsContainer = styled.div``;

export const InfoTags = styled.div`
  margin-top: 1.2rem;
`;

export const DashboardItemFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.6rem;
  border-top: 1px solid ${colors.gray1};
  margin-top: 1.6rem;
`;

export const InfoItems = styled.ul<{ images?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
  grid-template-rows: 1fr;
  grid-auto-flow: dense;
  column-gap: 1.5rem;
  row-gap: 2rem;

  ${({ images }) =>
    images &&
    `
    grid-template-rows: 20rem;
    grid-auto-rows: 20rem;
    grid-template-columns: repeat(auto-fit, 23rem);

    @media only screen and (max-width: 37.5em) {
      grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
    }
  `}
`;

export const InfoItem = styled.li<{
  lowercase?: boolean;
  uppercase?: boolean;
  withLink?: boolean;
  regularText?: boolean;
}>`
  & > span {
    display: block;
    text-transform: capitalize;

    &:first-child {
      font-weight: 600;
      font-size: 1.7rem;
    }

    &:last-child {
      ${({ lowercase }) => lowercase && 'text-transform: lowercase;'}

      ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}

      ${({ withLink }) =>
        withLink && `&>a{text-decoration:underline;color:${colors.blue};}`}

      ${({ regularText }) => regularText && 'text-transform: initial;'}
    }
  }
`;

export const ImageItem = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: ${colors.white1};

  & img {
    object-position: 50% 0%;
  }
`;

export const SummaryItemDescription = styled.span<{ description: string }>`
  display: ${({ description }) =>
    description.length > 100 ? 'block' : 'inline-block'};
  max-width: 35rem;
`;
