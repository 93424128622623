import styled, { keyframes } from 'styled-components';

import { colors } from '~/lib/colors';

const inward = keyframes`
    0%{
        opacity: 0;
        transform: scale(0.7);
    };
    
    100%{
        opacity: 1;
        transform: scale(1);
    };
`;

export interface MoreOptionsListItemStyleProps {
  danger?: boolean;
  withSvg?: boolean;
}

export const MoreOptionsList = styled.ul`
  border-radius: 5px;
  background-color: ${colors.white};
  margin: 1rem;
  padding: 1rem 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  animation: ${inward} 0.1s ease-in-out forwards;
  transform-origin: 100% 0%;
  z-index: 9999999999999999999999999;
`;

export const MoreOptionsListItem = styled.li<MoreOptionsListItemStyleProps>`
  padding: 8px 6rem;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover,
  &:focus,
  &:focus-within {
    background-color: #e6fff7;
  }

  ${({ danger }) => danger && `color: ${colors.error};`}

  ${({ withSvg }) => withSvg && '&  svg { margin-right: 1rem;}'}
`;
