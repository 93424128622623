import styled from 'styled-components';
import { motion } from 'framer-motion';

import { colors } from '~/lib/colors';

interface ISideBarLinkStyleProps {
  isActive: boolean;
}

export const SideBarOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1200;
`;

export const SideBarWrapper = styled(motion.nav)`
  z-index: 99999999999;
  width: 31rem;
  font-size: 1.5rem;
  line-height: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: ${colors.white};
  padding: 5.2rem 4.8rem 6.4rem;
  padding-left: 3.8rem;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 1.3rem 3.6rem rgba(0, 0, 0, 0.22));
  will-change: transform, opacity;

  & > svg:first-of-type {
    align-self: flex-end;
    cursor: pointer;
    position: fixed;
    top: 5.2rem;
    right: 4.8rem;
  }
`;

export const SideBarLinks = styled.ul`
  margin-top: calc(1.4rem + 5.8rem);
  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  scrollbar-width: thin;

  & > button {
    width: 17.4rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

export const SideBarLink = styled.li<ISideBarLinkStyleProps>`
  position: relative;
  width: fit-content;
  padding: 2px 2.7rem 2px 2rem;
  border-left: 4px solid
    ${({ isActive }) => (isActive ? colors.primary : 'transparent')};
  transition: border-left-color 0.2s ease-in-out;

  & > a {
    text-transform: capitalize;
    color: ${({ isActive }) => (isActive ? colors.gray : colors.black)};
    transition: color 0.2s ease-in-out;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  &:last-child {
    margin-bottom: 3rem;
  }
`;
