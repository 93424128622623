import { FC, ComponentPropsWithRef, forwardRef } from 'react';

import { Svg, ISvgStyleProps } from './svg-icon.styles';

interface ISvgIconProps extends ISvgStyleProps, ComponentPropsWithRef<'svg'> {
  iconName: string;
}

const SvgIcon: FC<ISvgIconProps> = forwardRef(
  ({ iconName, ...otherProps }, ref) => {
    return (
      <Svg ref={ref} {...otherProps}>
        <use href={'/sprite.svg' + '#icon-' + iconName} />
      </Svg>
    );
  }
);

export default SvgIcon;
