/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useState, useEffect, ReactElement } from 'react';
import Popup from 'reactjs-popup';
import { StyledComponent } from 'styled-components';

import {
  MoreOptionsList,
  MoreOptionsListItem,
  MoreOptionsListItemStyleProps,
} from './more-options.styles';

import SvgIcon from '../svg-icon/svg-icon.component';

interface IMoreOptionsProps {
  children: ReactNode;
  trigger?: ReactElement;
}

type MoreOptions = FC<IMoreOptionsProps> & {
  ListItem: StyledComponent<'li', any, MoreOptionsListItemStyleProps>;
};

const MoreOptions: MoreOptions = ({ children, trigger }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? (
    <Popup
      trigger={
        trigger ?? (
          <SvgIcon
            iconName="more-options"
            black
            tabIndex={0}
            style={{ cursor: 'pointer' }}
          />
        )
      }
      position="bottom right"
      on={['focus', 'click']}
      arrow={false}
      mouseEnterDelay={500}
      mouseLeaveDelay={500}
      contentStyle={{
        zIndex: 998,
        width: 'fit-content',
        border: 'none',
        background: 'transparent',
        boxShadow: 'none',
        padding: 0,
      }}
      closeOnDocumentClick
    >
      <MoreOptionsList>{children}</MoreOptionsList>
    </Popup>
  ) : null;
};

MoreOptions.ListItem = MoreOptionsListItem;

export default MoreOptions;
