import styled from 'styled-components';

import { colors } from '~/lib/colors';

export interface IBtnStyleProps {
  block?: boolean;
  transparentBg?: boolean;
  semitransparentBg?: boolean;
  br5?: boolean;
  danger?: boolean;
}

export const Btn = styled.button<IBtnStyleProps>`
  border: 1.5px solid transparent;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: inherit;
  background-color: ${colors.gray1};
  color: ${colors.white};
  padding: 0.8rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 2px;
  outline: transparent dashed 1.5px;
  outline-offset: 2px;
  transition-property: background-color, outline-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  &:hover {
    background-color: ${colors.gray};
  }

  &:focus-visible {
    outline-color: ${colors.black};
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  ${({ block }) => block && 'width: 100%;'}

  ${({ br5 }) => br5 && 'border-radius: 5px;'}

  ${({ transparentBg }) =>
    transparentBg &&
    `
    background-color: transparent;
    color: ${colors.gray1};
    border-color: ${colors.gray1};

    &:hover {
      background-color: ${colors.gray1};
      border-color: ${colors.gray1};
      color: ${colors.white};
    }
  `}

  ${({ semitransparentBg }) =>
    semitransparentBg &&
    `
    background-color: ${colors.white1};
    color: ${colors.black1};

    & > svg{
      height: 1.3rem;
      width: 1.3rem;
      margin-right: 1rem;
    }

    &:hover {
      background-color: ${colors.white2};
    }
  `}
  
  ${({ danger }) =>
    danger &&
    `
    background-color: ${colors.error};
    color: ${colors.white};

    & > svg{
      height: 1.3rem;
      width: 1.3rem;
      margin-right: 1rem;
    }

    &:hover {
      background-color: ${colors.error};
    }
  `}
`;
