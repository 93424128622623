import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import { colors } from '~/lib/colors';

const PageLoader: FC = () => {
  return (
    <Body>
      <Borders />

      <p style={{ marginTop: '5rem' }}>Loading...</p>
    </Body>
  );
};

const borderSpin = keyframes`
  to {
      transform: rotate(360deg);
    }
`;

export const Borders = styled.div`
  align-items: center;
  height: 5rem;
  display: flex;
  justify-content: center;
  position: relative;
  width: 5rem;

  &:after,
  &:before {
    border: 0.4rem solid ${colors.white};
    border-radius: 100%;
    position: absolute;
    content: '';
    display: block;
  }

  &:before {
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: ${borderSpin} 0.9s infinite linear reverse;
    height: 3rem;
    width: 3rem;
  }

  &:after {
    animation: ${borderSpin} 0.65s infinite linear;
    height: 5rem;
    width: 5rem;
    border-right-color: transparent;
    border-top-color: transparent;
  }
`;

const Body = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${colors.primary};
  color: ${colors.white};
`;

export default PageLoader;
