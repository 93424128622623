import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { Id, toast } from 'react-toastify';
import { useRouter } from 'next/router';

import ReactQueryKeys from '../keys';
import { apiV1 } from '../utils';

import { clearItems } from '../../utils/localStorage';

import routes from '~/helpers/routes';

import useStore from '../../store';

export const logOut = async () => {
  const response = await apiV1.post('/logout/');

  return response.data;
};

const useLogOut = () => {
  let toastId: Id;

  const router = useRouter();

  const updateUser = useStore((state) => state.updateUser);

  return useMutation(logOut, {
    mutationKey: ReactQueryKeys.LOG_OUT,
    onMutate: () => {
      toastId = toast.info('Logging you out...');
    },
    onSuccess: () => {
      updateUser(null);

      clearItems();

      toast.update(toastId, {
        render:
          'You have been logged out successfully. You would be redirected shortly',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });

      setTimeout(() => {
        router.push(routes.login());

        toast.dismiss(toastId);
      }, 3000);
    },
    onError: (error: AxiosError) => {
      toast.update(toastId, {
        render: error?.response?.data?.message,
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
    },
  });
};

export default useLogOut;
