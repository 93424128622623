import {
  FC,
  ComponentPropsWithRef,
  ReactNode,
  forwardRef,
  ForwardedRef,
} from 'react';
import SvgIcon from '../svg-icon/svg-icon.component';

import { Btn, IBtnStyleProps } from './button.styles';

interface IButtonProps extends ComponentPropsWithRef<'button'>, IBtnStyleProps {
  children: ReactNode;
  isLoading?: boolean;
  loadingText?: string;
}

const Button: FC<IButtonProps> = forwardRef(
  ({ children, isLoading, loadingText, ...otherProps }, ref) => {
    return (
      <Btn ref={ref} disabled={isLoading} {...otherProps}>
        {isLoading ? loadingText ?? 'Loading...' : children}
      </Btn>
    );
  }
);

export const AddButton: FC<{ buttonText: string; onClick?: () => void }> =
  forwardRef(
    ({ buttonText, ...otherProps }, ref: ForwardedRef<HTMLButtonElement>) => {
      return (
        <Button ref={ref} {...otherProps} semitransparentBg br5>
          <SvgIcon iconName="plus" black small /> {buttonText}
        </Button>
      );
    }
  );

export default Button;
