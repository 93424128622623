import styled from 'styled-components';

import { colors } from '~/lib/colors';

export interface ISvgStyleProps {
  black?: boolean;
  small?: boolean;
  big?: boolean;
}

export const Svg = styled.svg<ISvgStyleProps>`
  height: 1.6rem;
  width: 1.6rem;
  fill: ${colors.primary};
  outline: transparent dashed 1.5px;
  outline-offset: 2px;
  transition: outline-color 0.2s;

  &:focus {
    outline-color: ${colors.black};
  }

  ${({ small }) => small && `height: 1.4rem; width: 1.4rem;`}

  ${({ black }) => black && `fill: ${colors.black};`};

  ${({ big }) => big && `height: 2.4rem; width: 2.4rem;`}
`;
