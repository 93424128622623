import { FC, ReactNode } from 'react';

interface IShowViewProps {
  children: ReactNode;
  when: boolean | undefined;
}

const ShowView: FC<IShowViewProps> = ({ children, when }) => {
  if (!when) return null;

  return <>{children}</>;
};

export default ShowView;
